<template>
    <el-row :gutter="30">
        <el-col :span="12">

          <el-row>
            <el-col class="section-title">
              NOTAS DEL PEDIDO
            </el-col>
          </el-row>

          <el-row class="mt-3">
            <el-col class="d-flex flex-column justify-content-between align-items-start">
              <template v-if="editOrderNote">
                <el-input
                    v-model="orderNote"
                    type="textarea"
                    :autosize="{ minRows: 4}"
                    maxlength="512"
                    placeholder="Máx 512 caracteres">
                </el-input>
                <el-button
                    class="mt-3"
                    type="primary"
                    :loading="saveNoteLoading"
                    :disabled="!orderNote"
                    @click="saveNote">
                  <i class="el-icon-check"></i> Guardar
                </el-button>
                <div>
                  <el-button
                      class="mt-3"
                      type="secondary"
                      @click="editOrderNote = false">
                    <i class="el-icon-close"></i> Cancelar
                  </el-button>
                </div>
              </template>
              <template v-else-if="order.note">
                <date-time-formatted :date="new Date(order.noteDate)"/>
                <span class="mt-1">{{ order.note }}</span>
                <span class="cursor-pointer font-weight-bold mt-3" @click.prevent="orderNote = order.note; editOrderNote = true">
                  <u>Editar Nota</u>
                </span>
              </template>
              <template v-else>
                <span class="cursor-pointer" @click.prevent="editOrderNote = true">
                    + Añadir Nota
                </span>
              </template>
            </el-col>
          </el-row>

            <el-row class="mt-4">
                <el-col class="section-title">
                    RESUMEN DE PEDIDO
                </el-col>
            </el-row>

            <el-row class="mt-3">
                <el-col id="cake-name">
                    {{ order.cake.name }}
                </el-col>
            </el-row>

            <el-row class="mt-2">
                <el-col class="d-flex flex-row justify-content-between align-items-center">
                    <span class="price">Precio Total</span>

                    <span>
                            <template v-if="priceEditionEnabled">
                                <span class="no-wrap">
                                    <currency-input
                                        v-model="prices.cake"
                                        placeholder="Ej. 36"
                                        size="medium"
                                        classes="max-width-small"></currency-input> €
                                </span>
                            </template>

                            <template v-else-if="totalPrice">
                                <span class="no-wrap">{{ totalPrice }} €</span>
                            </template>

                            <template v-else>&nbsp;</template>
                        </span>
                </el-col>
            </el-row>

            <div v-if="hasDiscount">

                <el-row class="mt-2">
                    <el-col class="d-flex flex-row justify-content-between">
                        <span class="price">{{ order.discountCode }} <template v-if="isFreeDelivery">Envío Gratis</template><template v-else>-{{ discount}}€</template></span>

                        <span>-{{ discount}} €</span>
                    </el-col>
                </el-row>

                <el-row class="mt-2">
                    <el-col class="d-flex flex-row justify-content-between">
                        <span class="price">Precio Final</span>

                        <span><strong>{{ totalPriceWithDiscount }} €</strong></span>
                    </el-col>
                </el-row>
            </div>

            <el-row class="mt-4">
                <el-col class="label-red">
                    Personalizaciones
                </el-col>
            </el-row>

            <el-row class="mt-2">
                <el-col class="d-flex flex-row justify-content-between">
                    <span>{{ order.portions }} Raciones</span>

                    <span v-if="order.portionsPrice && !order.byBudget">{{ order.portionsPrice }} €</span>
                </el-col>
            </el-row>

            <el-row v-if="order.filling" class="mt-2">
                <el-col class="d-flex flex-row justify-content-between">

                    <span>
                        Relleno
                        <i class="mt-1">
                            <br/>{{ order.filling.name }}
                        </i>
                    </span>

                    <span v-if="order.filling.price && !order.byBudget">{{ order.filling.price }} €</span>
                </el-col>
            </el-row>

            <el-row v-if="order.flour" class="mt-2">
                <el-col class="d-flex flex-row justify-content-between">

                    <span>
                        Harina
                        <i class="mt-1">
                            <br/>{{ order.flour.name }}
                        </i>
                    </span>

                    <span v-if="order.flour.price && !order.byBudget">{{ order.flour.price }} €</span>
                </el-col>
            </el-row>

            <el-row v-if="order.topping" class="mt-2">
                <el-col class="d-flex flex-row justify-content-between">

                    <span>
                        Cobertura
                        <i class="mt-1">
                            <br/>{{ order.topping.name }}
                        </i>
                    </span>

                    <span v-if="order.topping.price && !order.byBudget">{{ order.topping.price }} €</span>
                </el-col>
            </el-row>

            <el-row v-if="order.sweetener" class="mt-2">
              <el-col class="d-flex flex-row justify-content-between">

                      <span>
                          Endulzante
                          <i class="mt-1">
                              <br/>{{ order.sweetener.name }}
                          </i>
                      </span>

                <span v-if="order.sweetener.price && !order.byBudget">{{ order.sweetener.price }} €</span>
              </el-col>
            </el-row>

            <el-row
                v-for="(customization, customizationsIndex) in order.customizations"
                :key="customizationsIndex"
                class="mt-2">
                <el-col class="d-flex flex-row justify-content-between">
                    <span>
                        {{ customization.name }}
                        <i v-if="customization.annotation" class="mt-1">
                            <br/>{{ customization.annotation }}
                        </i>
                        <div v-else-if="customization.image">
                            <el-row class="mt-2 inspiring-image-container">
                                <image-slider :image-urls="[customization.image]"></image-slider>
                            </el-row>
                            <el-row class="mt-2">
                                <image-url-download :url="customization.image">
                                    Descargar Imagen
                                </image-url-download>
                            </el-row>
                        </div>
                    </span>

                    <span>
                        <template v-if="customization.price && !order.byBudget">
                            <span class="no-wrap">{{ customization.price }} €</span>
                        </template>

                        <template v-else-if="!order.byBudget">GRATIS</template>

                        <template v-else>&nbsp;</template>
                    </span>
                </el-col>
            </el-row>

            <template v-if="order.dedication">
                <el-row class="mt-4">
                    <el-col class="label-red">
                        Tarjeta dedicatoria
                    </el-col>
                </el-row>

                <el-row class="mt-2">
                    <el-col class="font-italic">
                        <span>{{ order.dedication }}</span>
                    </el-col>
                </el-row>
            </template>

            <template v-if="order.inspiringImage">
                <el-row class="mt-4">
                    <el-col class="label-red">
                        Imagen Inspiradora
                    </el-col>
                </el-row>

                <el-row class="mt-2 inspiring-image-container">
                    <image-slider :image-urls="[order.inspiringImage]"></image-slider>
                </el-row>

                <el-row class="mt-2">
                    <image-url-download :url="order.inspiringImage">
                        Descargar Imagen
                    </image-url-download>
                </el-row>
            </template>

            <el-row class="mt-4">
                <el-col class="label-red">
                    Envío
                </el-col>
            </el-row>

            <el-row class="mt-2">
                <el-col class="d-flex flex-row justify-content-between align-items-center">
                    <shipping-type :value="order.shippingType"/>

                    <span>
                        <template v-if="shippingPriceEditionEnabled">
                            <span class="no-wrap">
                                <currency-input
                                    v-model="prices.shipping"
                                    placeholder="Ej. 10"
                                    size="medium"
                                    classes="max-width-small"></currency-input> €
                            </span>
                        </template>

                        <template v-else-if="order.shippingPrice">
                            <span class="no-wrap">{{ order.shippingPrice }} €</span>
                        </template>

                        <template v-else>&nbsp;</template>
                    </span>
                </el-col>
            </el-row>

            <el-row class="mt-3">
                <el-col>
                    <date-formatted :date="new Date(order.deliveryDate)"/>
                </el-col>
            </el-row>

            <el-row class="mt-1">
                <el-col>
                    <delivery-time-slot :value="order.deliveryTimeSlot"/>
                </el-col>
            </el-row>

            <template v-if="order.shippingType === 'IN_SHOP' && order.cakeshopAddress">
                <el-row class="mt-3">
                    <el-col>
                        {{ order.cakeshopAddress.address }}
                    </el-col>
                </el-row>

                <el-row class="mt-1">
                    <el-col>
                        {{ order.cakeshopAddress.postalCode }}
                    </el-col>
                </el-row>

                <el-row class="mt-1">
                    <el-col>
                        {{ order.cakeshopAddress.zone }}
                    </el-col>
                </el-row>

                <el-row class="mt-1">
                    <el-col>
                        {{ order.cakeshopAddress.city.name }}
                    </el-col>
                </el-row>
            </template>

            <template v-else-if="order.address">
                <el-row class="mt-3">
                    <el-col>
                        {{ order.address.addressee }}
                    </el-col>
                </el-row>

                <el-row class="mt-1">
                    <el-col>
                        {{ order.address.address }}
                    </el-col>
                </el-row>

                <el-row class="mt-1">
                    <el-col>
                        {{ order.address.postalCode }}, {{ order.address.city }}
                    </el-col>
                </el-row>
            </template>

            <template v-else-if="order.guestAddress">
                <el-row class="mt-3">
                    <el-col>
                        Entrega a otra persona:
                    </el-col>
                </el-row>

                <el-row class="mt-1">
                    <el-col>
                        {{ order.guestAddress.addressee }}
                    </el-col>
                </el-row>

                <el-row class="mt-1">
                  <el-col>
                    {{ order.guestAddress.phone }}
                  </el-col>
                </el-row>

                <el-row class="mt-1">
                    <el-col>
                        {{ order.guestAddress.address }}
                    </el-col>
                </el-row>

                <el-row class="mt-1">
                    <el-col>
                        {{ order.guestAddress.postalCode }}, {{ order.guestAddress.city }}
                    </el-col>
                </el-row>
            </template>

            <el-row v-if="order.deliveryClarification" class="mt-3">
                <el-col>
                    {{ order.deliveryClarification }}
                </el-col>
            </el-row>

            <template v-if="order.feedbackRate">
                <el-row class="mt-4">
                    <el-col class="label-red">
                        Valoración
                    </el-col>
                </el-row>

                <el-row class="mt-3">
                    <el-col>
                        <star-rate :value="order.feedbackRate"/>
                    </el-col>
                </el-row>

                <el-row v-if="order.feedbackComment" class="mt-3">
                    <el-col>
                        {{ order.feedbackComment }}
                    </el-col>
                </el-row>
            </template>
        </el-col>

        <el-col :span="12">
            <el-row>
                <el-col class="section-title">
                    HISTÓRICO DE PEDIDO
                </el-col>
            </el-row>

            <el-row v-for="(memento, index) in mementos" :key="'memento'+index" class="mt-3 mb-4">
                <el-col>
                    <el-row>
                        <el-col class="label-red">
                            <order-status :status="memento.status"/>
                        </el-col>
                    </el-row>

                    <el-row class="mt-2">
                        <el-col>
                            <date-time-formatted :date="new Date(memento.createdAt)"/>
                        </el-col>
                    </el-row>

                    <template v-if="memento.status === 'PENDING_APPROVAL' && order.commentary">
                        <el-row class="mt-3">
                            <el-col class="label">
                                Comentario del pedido
                            </el-col>
                        </el-row>

                        <el-row class="mt-2">
                            <el-col>
                                {{ order.commentary }}
                            </el-col>
                        </el-row>
                    </template>

                    <template v-else-if="memento.status === 'CANCELLED' && order.cancelledBy && order.cancelCommentary">
                        <el-row class="mt-3">
                            <el-col class="label">
                                Mensaje de cancelación de
                                <template v-if="cancelledByClient">comprador</template>
                                <template v-else-if="cancelledByAdmin">admin</template>
                                <template v-else-if="cancelledByCakeshop">pastelería</template>
                            </el-col>
                        </el-row>

                        <el-row class="mt-2">
                            <el-col>
                                {{ order.cancelCommentary }}
                            </el-col>
                        </el-row>
                    </template>
                </el-col>
            </el-row>

            <template v-if="requested">
                <el-row class="mt-4">
                    <el-col class="label">
                        Comentario del pedido
                    </el-col>
                </el-row>

                <el-row class="mt-3">
                    <el-col>
                        <el-input
                            v-model="commentary"
                            type="textarea"
                            :autosize="{ minRows: 3}"
                            placeholder="Mín 10 - Máx 255 caracteres">
                        </el-input>
                    </el-col>
                </el-row>

                <el-row v-if="requested" id="approval-actions" class="mt-3">
                    <el-col>
                        <div>
                            <template v-if="priceEditionEnabled">
                                <el-button
                                    type="primary"
                                    :loading="toPendingApprovalLoading"
                                    :disabled="!pricesFilled"
                                    @click="setPrices">
                                    <i class="el-icon-check"></i> Aprobar
                                </el-button>
                            </template>
                            <template v-else>
                                <el-button
                                    type="primary"
                                    :loading="toPendingApprovalLoading"
                                    @click="toPendingApproval">
                                    <i class="el-icon-check"></i> Aprobar
                                </el-button>
                            </template>
                        </div>

                        <div class="mt-3" v-show="role === 'ADMIN'">
                            <el-button
                                type="secondary"
                                :disabled="!commentaryFilled"
                                :loading="cancelByCakeshopLoading"
                                @click="cancelByCakeshop">
                                <i class="el-icon-close"></i> Rechazar
                            </el-button>
                        </div>
                    </el-col>
                </el-row>
            </template>

            <template v-else-if="readyToDeliver">
                <el-row id="deliver-actions" class="mt-4">
                    <el-col>
                        <div>
                            <el-button
                                type="primary"
                                :loading="deliverLoading"
                                @click="deliver">
                                <i class="el-icon-check"></i> Entregado
                            </el-button>
                        </div>

                        <div class="mt-3">
                            <el-button
                                type="secondary"
                                @click="cancelModalVisible = true">
                                <i class="el-icon-close"></i> No Entregado
                            </el-button>
                        </div>
                    </el-col>
                </el-row>
            </template>

            <template v-if="(finishedPayments.length || order.payOnDelivery) && role === 'ADMIN'">
                <el-row class="mt-5">
                    <el-col class="section-title">
                        MENSAJES DE STRIPE
                    </el-col>
                </el-row>

                <el-row v-if="order.payOnDelivery" class="mt-2">
                  *Pedido a pagar a la entrega
                </el-row>

                <el-row v-for="(payment, paymentsIndex) in finishedPayments" :key="paymentsIndex" class="mt-2">
                    <span class="label">{{payment.amount}}€</span><br/>
                    <template v-if="payment.status === 'FAILED' && !!payment.commentary">
                        {{payment.commentary}}<br/>
                    </template>
                    <date-time-formatted :date="new Date(payment.updatedAt)"/>
                </el-row>

                <el-row v-if="canNotifyLastPayment" class="mt-2">
                    <el-col>
                        <el-button
                            :loading="notifying"
                            class="notify-payment-button py-2"
                            @click="notifyFailedPayment(lastFinishedPayment.uuid)">
                            Volver a Notificar
                        </el-button>
                    </el-col>
                </el-row>
            </template>

            <el-row v-if="isCancellable" class="mt-4" v-show="role === 'ADMIN'">
                <el-col>
                    <span id="cancel-action" @click.prevent="cancelModalVisible = true">
                        <i class="el-icon-error"></i> Cancelar pedido
                    </span>

                    <el-dialog
                        :visible.sync="cancelModalVisible"
                        width="30%"
                        @closed="cancelCommentary = null">
                        <div id="cancel-label">
                            Describe el motivo de la cancelación:
                        </div>
                        <div class="mt-2">
                            <el-input
                                v-model="cancelCommentary"
                                type="textarea"
                                :autosize="{ minRows: 3}"
                                placeholder="Mín 10 - Máx 255 caracteres">
                            </el-input>
                        </div>

                        <div slot="footer" class="dialog-footer">
                            <el-button
                                type="primary"
                                :disabled="!this.cancelCommentary || this.cancelCommentary.length < 10 || this.cancelCommentary.length > 255"
                                :loading="cancelLoading"
                                @click="cancel">
                                Cancelar Pedido
                            </el-button>
                        </div>
                    </el-dialog>
                </el-col>
            </el-row>
        </el-col>
    </el-row>
</template>

<script>
    import {messages} from '../../../../constants/text-constants';
    import {mapGetters} from 'vuex';
    import {
        cancel,
        setPrices,
        setStatusAsPendingApproval,
        deliver,
        saveNote,
    } from '../../../../api/order-api';
    import {notifyFailedPayment} from '../../../../api/payment-api';
    import DateFormatted from '../../../../components/DateFormatted';
    import DateTimeFormatted from '../../../../components/DateTimeFormatted';
    import ShippingType from '../../../../components/ShippingType';
    import DeliveryTimeSlot from '../../../../components/DeliveryTimeSlot';
    import CurrencyInput from '../../../../components/CurrencyInput';
    import OrderStatus from '../../../../components/OrderStatus';
    import ImageSlider from '../../../../components/ImageSlider';
    import ImageUrlDownload from '../../../../components/ImageUrlDownload';
    import StarRate from '../../../../components/StarRate';

    export default {
        name: "OrderActions",
        components: {
            DateFormatted,
            DateTimeFormatted,
            ShippingType,
            DeliveryTimeSlot,
            CurrencyInput,
            OrderStatus,
            ImageSlider,
            ImageUrlDownload,
            StarRate,
        },
        props: {
            value: {
                type: Object,
                default: function () {
                    return {
                        uuid: null
                    }
                }
            }
        },
        created() {
            this.order = Object.assign({}, this.value);
        },
        data() {
            return {
                order: {
                    uuid: null
                },
                prices: {
                    cake: null,
                    shipping: null
                },
                commentary: null,
                fromCakeshop: false,
                cancelCommentary: null,
                cancelModalVisible: false,
                action: null,
                loading: false,
                mementoTab: null,
                notifying: false,
                orderNote: null,
                editOrderNote: false
            }
        },
        computed: {
            ...mapGetters([
                'role'
            ]),
            requested() {
                return this.order.status === 'REQUESTED'
            },
            cancelled() {
                return this.order.status === 'CANCELLED'
            },
            readyToDeliver() {
                return this.order.status === 'READY_TO_DELIVER'
            },
            delivered() {
                return this.order.status === 'DELIVERED'
            },
            cancelledByClient() {
                return this.cancelled && this.order.client.uuid === this.order.cancelledBy.uuid
            },
            cancelledByAdmin() {
                return this.cancelled && this.order.client.uuid !== this.order.cancelledBy.uuid && !this.order.cancelledByCakeshop
            },
            cancelledByCakeshop() {
                return this.cancelled && this.order.client.uuid !== this.order.cancelledBy.uuid && this.order.cancelledByCakeshop
            },
            priceEditionEnabled() {
                return this.order.uuid
                    && this.order.byBudget
                    && this.requested
            },
            shippingPriceEditionEnabled() {
                return this.priceEditionEnabled && this.order.shippingType !== 'IN_SHOP'
            },
            isCancellable() {
                return this.order.uuid
                    && !this.delivered
                    && !this.cancelled
            },
            commentaryFilled() {
                return !!this.commentary && this.commentary.length >= 10 && this.commentary.length <= 255
            },
            pricesFilled() {
                return this.isValidCurrency(this.prices.cake)
                    && (
                        !this.shippingPriceEditionEnabled
                        || (this.shippingPriceEditionEnabled && this.isValidCurrency(this.prices.shipping))
                    )
            },
            totalPrice() {
                return this.order.cakePrice
                    ? this.order.cakePrice + (this.order.shippingPrice ? this.order.shippingPrice : 0)
                    : null;
            },
            totalPriceWithDiscount() {
                return this.order.discount
                    ? this.totalPrice - this.discount
                    : null;
            },
            hasDiscount() {
              return this.order.discountType !== null && this.order.discountType !== undefined;
            },
            isFreeDelivery() {
                return this.order.discountType === 'FREE_DELIVERY';
            },
            discount() {
              if (this.hasDiscount) {
                  switch (this.order.discountType) {
                      case 'FREE_DELIVERY':
                          return this.order.shippingPrice;
                      case 'MONEY_DISCOUNT':
                          return this.order.discount;
                      case 'PERCENTAGE_DISCOUNT':
                          return this.totalPrice * this.order.discount / 100;
                  }
              }
              return 0;
            },
            cancelLoading() {
                return this.cancelModalVisible && this.loading;
            },
            cancelByCakeshopLoading() {
                return this.loading && this.action === 'cancel';
            },
            toPendingApprovalLoading() {
                return this.loading && this.action === 'toPendingApproval';
            },
            deliverLoading() {
                return this.loading && this.action === 'deliver';
            },
            saveNoteLoading() {
              return this.loading && this.action === 'saveNote';
            },
            mementos() {
                return Array.isArray(this.order.mementos) && this.order.mementos.length
                    ? this.order.mementos
                    : [];
            },
            finishedPayments() {
                return Array.isArray(this.order.payments) && this.order.payments.length
                    ? this.order.payments.filter(payment => (payment.status !== 'PENDING'))
                    : [];
            },
            lastFinishedPayment() {
                return this.finishedPayments.length
                    ? this.finishedPayments[this.finishedPayments.length - 1]
                    : null;
            },
            canNotifyLastPayment() {
                return this.lastFinishedPayment && !this.cancelled
                    ? this.lastFinishedPayment
                    && this.lastFinishedPayment.status === 'FAILED'
                    && (this.lastFinishedPayment.requiredAction === 'ADD_NEW_CARD' || this.lastFinishedPayment.requiredAction === 'AUTHORIZE')
                    : false;
            }
        },
        methods: {
            saveNote() {
              this.action = 'saveNote';
              this.loading = true;
              saveNote(this.order.uuid, this.orderNote)
                  .then((order) => {
                    this.$emit('input', order);
                    this.$message.success(messages.order.note.success);
                    this.editOrderNote = false;
                  })
                  .catch(() => {
                    this.$message.error(messages.order.note.error);
                  })
                  .finally(() => {
                    this.action = null;
                    this.loading = false;
                  });
            },
            cancelByCakeshop() {
                this.fromCakeshop = true;
                this.cancelCommentary = this.commentary;
                this.cancel();
            },
            cancel() {
                this.action = 'cancel';
                this.loading = true;
                cancel(this.order.uuid, this.cancelCommentary, this.fromCakeshop)
                    .then((order) => {
                        this.$emit('input', order);
                        this.$message.success(messages.order.cancel.success);
                        this.cancelModalVisible = false;
                    })
                    .catch(() => {
                        this.$message.error(messages.order.cancel.error);
                    })
                    .finally(() => {
                        this.action = null;
                        this.loading = false;
                    });
            },
            setPrices() {
                this.action = 'toPendingApproval';
                this.loading = true;
                setPrices(this.order.uuid, this.prices.cake, this.prices.shipping)
                    .then(() => {
                        this.toPendingApproval();
                    })
                    .catch(() => {
                        this.$message.error(messages.order.pricing.error);
                        this.action = null;
                        this.loading = false;
                    });
            },
            toPendingApproval() {
                this.action = 'toPendingApproval';
                this.loading = true;
                setStatusAsPendingApproval(this.order.uuid, this.commentary)
                    .then((order) => {
                        this.$emit('input', order);
                        this.$message.success(messages.order.toPendingApproval.success);
                    })
                    .catch(() => {
                        this.$message.error(messages.order.toPendingApproval.error);
                    })
                    .finally(() => {
                        this.action = null;
                        this.loading = false;
                    });
            },
            deliver() {
                this.action = 'deliver';
                this.loading = true;
                deliver(this.order.uuid)
                    .then((order) => {
                        this.$emit('input', order);
                        this.$message.success(messages.order.deliver.success);
                    })
                    .catch(() => {
                        this.$message.error(messages.order.deliver.error);
                    })
                    .finally(() => {
                        this.action = null;
                        this.loading = false;
                    });
            },
            notifyFailedPayment(paymentId) {
                this.notifying = true;
                notifyFailedPayment(paymentId)
                    .then(() => {
                        this.$message.success(messages.payment.notifyFailed.success);
                    })
                    .catch(() => {
                        this.$message.error(messages.payment.notifyFailed.error);
                    })
                    .finally(() => {
                        this.notifying = false;
                    });
            },
            isValidCurrency(currency) {
                return (/^\d+([.,]\d{1,2})?$/.test(currency));
            },
            goToMementoTab(tab) {
                this.mementoTab = tab;
            }
        },
        watch: {
            value: function () {
                this.order = Object.assign({}, this.value);
                this.mementoTab = null;
            },
            commentary: function () {
                if (!!this.commentary && this.commentary.length > 255) {
                    this.commentary = this.commentary.substring(0, 255);
                }
            },
            cancelCommentary: function () {
                if (!!this.cancelCommentary && this.cancelCommentary.length > 255) {
                    this.cancelCommentary = this.cancelCommentary.substring(0, 255);
                }
            }
        }
    }
</script>

<style scoped>
    .label {
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.02em;
        color: #493D35;
    }

    .label-red {
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0.02em;
        color: #D89172;
    }

    #cake-name {
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0.02em;
        color: #D89172;
    }

    .price {
        font-weight: 600;
        font-size: 14px;
        color: #917F81;
    }

    #cancel-label {
        font-weight: 500;
        font-size: 14px;
        color: #493D35;
    }

    #cancel-action {
        font-weight: 600;
        font-size: 12px;
        color: #917F81;
        cursor: pointer;
    }

    #approval-actions >>> .el-button {
        width: 140px !important;
        text-align: left;
    }

    #deliver-actions >>> .el-button {
        width: 165px !important;
        text-align: left;
    }

    #history >>> .el-step__icon {
        cursor: pointer;
    }

    .inspiring-image-container {
        max-width: 100px;
    }

    .link {
        font-weight: bold;
        font-size: 12px;
        text-decoration-line: underline;
        color: #917F81;
    }

    .notify-payment-button {
        border: 1px solid #917F81;
        border-radius: 4px;
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        color: #917F81;
    }
</style>
