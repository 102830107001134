<template>
    <span>{{ statusSelected.text }}</span>
</template>

<script>
    export default {
        name: "OrderStatus",
        props: {
            status: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                statuses: [
                    {
                        text: 'Solicitado',
                        value: 'REQUESTED'
                    },
                    {
                        text: 'Pendiente',
                        value: 'PENDING_APPROVAL'
                    },
                    {
                        text: 'Confirmado',
                        value: 'CONFIRMED'
                    },
                    {
                        text: 'Cancelado',
                        value: 'CANCELLED'
                    },
                    {
                        text: 'Para entregar',
                        value: 'READY_TO_DELIVER'
                    },
                    {
                        text: 'Entregado',
                        value: 'DELIVERED'
                    },
                ]
            }
        },
        computed: {
            statusSelected() {
                return this.statuses.find(item => item.value === this.status)
            }
        }
    }
</script>

<style scoped>

</style>
