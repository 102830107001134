<template>
    <div class="d-flex flex-row align-items-center">
        <div>
            <span :class="[status.toLowerCase(), 'status-circle', 'mr-2']"></span>
        </div>
        <div>
            <order-status :status="status"/>
        </div>
    </div>
</template>

<script>
    import OrderStatus from '../OrderStatus';
    export default {
        name: "OrderStatusBadge",
        components: {
            OrderStatus
        },
        props: {
            status: {
                type: String,
                required: true
            }
        }
    }
</script>

<style scoped>
    .status-circle {
        height: 12px;
        width: 12px;
        background-color: #FAFAFA;
        border-radius: 50%;
        display: inline-block;
    }

    .requested {
        background-color: #FDCA4D;
    }

    .pending_approval {
        border: 2px solid #917F81;
    }

    .confirmed {
        background-color: #24A249;
    }

    .cancelled {
        background-color: #917F81;
    }

    .ready_to_deliver {
        background-color: #FDCA4D;
    }

    .delivered {
        background-color: #24A249;
    }
</style>