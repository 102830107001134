<template>
    <span>{{ formatted }}</span>
</template>

<script>
    export default {
        name: "TimeFormatted",
        props: {
            date: {
                type: Date,
                required: true
            }
        },
        computed: {
            formatted() {
                return this.appendLeadingZeroes(this.date.getHours())
                    + ":" + this.appendLeadingZeroes(this.date.getMinutes());
            }
        },
        methods: {
            appendLeadingZeroes(n) {
                if (n <= 9) {
                    return "0" + n;
                }
                return n
            }
        }
    }
</script>

<style scoped>

</style>