<template>
    <span>{{ byBudgetName }}</span>
</template>

<script>
    export default {
        name: "CakeByBudget",
        props: {
            byBudget: {
                type: Boolean,
                required: true
            }
        },
        computed: {
            byBudgetName() {
                return this.byBudget ? 'Presupuesto' : 'Estándar'
            }
        }
    }
</script>

<style scoped>

</style>