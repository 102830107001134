<template>
    <div class="d-flex flex-row justify-content-start">
        <i v-for="index in filledStars" :key="'filled'+index" class="el-icon-star-on"></i>
        <i v-for="index in notFilledStars" :key="index" class="el-icon-star-off"></i>
    </div>
</template>

<script>
    export default {
        name: "StarRate",
        props: {
            value: {
                type: Number,
                default: 0,
            },
        },
        computed: {
            filledStars() {
                return this.value > 5 ? 5 : (this.value < 1 ? 1 : this.value)
            },
            notFilledStars() {
                return 5 - this.filledStars
            },
        },
    }
</script>

<style scoped>
    i {
        font-size: 18px;
    }

    .el-icon-star-on {
        color: #C45F24;
    }

    .el-icon-star-off {
        color: #D89172;
    }
</style>