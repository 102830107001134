<template>
    <div class="my-5">
        <el-row type="flex" align="middle">
            <el-col :span="8">
                <div class="breadcrumbs" @click="goBack">
                    <svg-icon class="mr-1" icon-class="back-arrow"/>
                    Volver a Pedidos
                </div>
            </el-col>

            <el-col :span="8" class="text-center">
                <span class="uppercase-title">
                    <template v-if="order.number">Nº {{order.number}}</template>
                    <template v-else>Nº -</template>
                </span>
            </el-col>
        </el-row>

        <el-row class="mt-4 py-4 px-5 bg-white border border-gray rounded">
            <el-col>
                <el-row type="flex" align="middle">
                    <el-col :span="8">
                        &nbsp;
                    </el-col>

                    <el-col :span="8" class="text-center box-title">
                        Pedido
                        <cake-by-budget v-if="order.uuid" :by-budget="order.byBudget"></cake-by-budget>
                    </el-col>

                    <el-col :span="8" class="d-flex flex-row-reverse">
                        <div class="bg-light border border-gray rounded status-box p-2">
                            <order-status-badge :status="order.status"></order-status-badge>
                        </div>
                    </el-col>
                </el-row>

                <el-row class="mt-4">
                    <el-col :span="10" class="py-4">
                        <order-info :order="order" class="mt-1"/>
                    </el-col>

                    <el-col :span="14" class="bg-light border border-gray rounded px-5 py-4">
                        <order-actions v-model="order" @input="loadOrders"/>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import {messages} from '../../../constants/text-constants';
    import CakeByBudget from '../../../components/CakeByBudget';
    import OrderStatusBadge from '../../../components/OrderStatusBadge';
    import OrderInfo from './components/OrderInfo';
    import OrderActions from './components/OrderActions';

    export default {
        name: "OrderProfile",
        components: {
            CakeByBudget,
            OrderStatusBadge,
            OrderInfo,
            OrderActions
        },
        data() {
            return {
                order: {
                    uuid: null
                }
            }
        },
        created() {
            if (this.$route.params.uuid) {
                this.order = Object.assign({}, this.orderById(this.$route.params.uuid));
                if (!this.order.uuid) {
                    this.showGetError();
                    this.goBack();
                }
            } else {
                this.showGetError();
                this.goBack();
            }
        },
        computed: {
            ...mapGetters([
                'orderById'
            ])
        },
        methods: {
            showGetError() {
                this.$message.error(messages.order.get.error);
            },
            goBack() {
                this.$router.push({name: 'orders-list'})
            },
            loadOrders() {
                this.$store.dispatch('loadOrders');
            }
        }
    }
</script>

<style scoped>
    .status-box {
        width: fit-content;
    }
</style>
