<template>
    <span @click="downloadWithAxios" class="link">
        <slot></slot>
    </span>
</template>

<script>
    import axios from 'axios'

    export default {
        name: "ImageUrlDownload",
        props: {
            url: {
                type: String,
                required: true
            }
        },
        methods: {
            forceFileDownload(response) {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'inspirational-image.jpg')
                document.body.appendChild(link)
                link.click()
            },
            downloadWithAxios() {
                axios({
                    method: 'get',
                    url: this.url,
                    responseType: 'arraybuffer'
                }).then(response => {
                    this.forceFileDownload(response)
                })
            }
        }
    }
</script>

<style scoped>
    .link {
        font-weight: bold;
        font-size: 12px;
        text-decoration-line: underline;
        color: #917F81;
        cursor: pointer;
    }
</style>
