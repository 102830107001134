<template>
    <span>{{ deliveryTimeSlot }}</span>
</template>

<script>
    import {deliveryTimeSlots} from '../../constants/order-constants'

    export default {
        name: "DeliveryTimeSlot",
        props: {
            value: {
                type: String,
                required: true
            }
        },
        computed: {
            deliveryTimeSlot() {
                return deliveryTimeSlots[this.value] ? deliveryTimeSlots[this.value] : "Desconocido"
            }
        }
    }
</script>

<style scoped>

</style>