<template>
    <div>
        <el-row class="mb-1">
            <el-col class="section-title">
                DATOS PEDIDO
            </el-col>
        </el-row>

        <div v-show="role === 'ADMIN'">

            <el-row class="mt-2">
                <el-col class="label">
                    Cliente
                </el-col>
            </el-row>

            <el-row class="mt-2">
                <el-col>
                    {{ order.client.name }}
                </el-col>
            </el-row>

            <el-row class="mt-2">
                <el-col>
                    {{ order.client.email }}
                </el-col>
            </el-row>

            <el-row class="mt-2">
                <el-col>
                    {{ order.client.phone }}
                </el-col>
            </el-row>

        </div>

        <el-row class="mt-3">
            <el-col class="label">
                Fecha de Solicitud
            </el-col>
        </el-row>

        <el-row class="mt-2">
            <el-col>
                <date-formatted :date="new Date(order.createdAt)"/>
                <time-formatted :date="new Date(order.createdAt)" class="ml-2"/>
            </el-col>
        </el-row>

        <div v-show="role === 'ADMIN'">

            <el-row class="mt-3">
                <el-col class="label">
                    Id Cliente de Stripe
                </el-col>
            </el-row>

            <el-row class="mt-2">
                <el-col>
                    {{ order.client.paymentCustomerId }}
                </el-col>
            </el-row>

            <el-row class="mt-4 mb-1">
                <el-col class="section-title">
                    PASTELERÍA
                </el-col>
            </el-row>

            <el-row class="mt-2">
                <el-col>
                    {{ order.cake.cakeshop.name }}
                </el-col>
            </el-row>

            <el-row class="mt-2">
                <el-col>
                    {{ order.cake.cakeshop.phone }}
                </el-col>
            </el-row>

            <el-row class="mt-3">
                <el-col class="label">
                    Responsable
                </el-col>
            </el-row>

            <el-row class="mt-2">
                <el-col>
                    {{ order.cake.cakeshop.managerName }}
                </el-col>
            </el-row>

            <el-row class="mt-2">
                <el-col>
                    {{ order.cake.cakeshop.managerPhone }}
                </el-col>
            </el-row>

        </div>
    </div>
</template>

<script>
    import DateFormatted from '../../../../components/DateFormatted';
    import TimeFormatted from '../../../../components/TimeFormatted';
    import {mapGetters} from 'vuex';

    export default {
        name: "OrderInfo",
        components: {
            DateFormatted,
            TimeFormatted
        },
        props: {
            order: {
                type: Object,
                required: true,
                default: function () {
                    return {
                        uuid: null
                    }
                }
            }
        },
        computed: {
            ...mapGetters([
                'role'
            ])
        },
    }
</script>

<style scoped>
    .label {
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.02em;
        color: #493D35;
    }
</style>
